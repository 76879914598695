const moment = require("moment");

export const fullDateFormatter = dateString => {
  return moment(dateString).format("dddd Do MMMM YYYY");
};

export const shortDateFormatter = dateString => {
  return moment(dateString).format("D MMM YYYY");
};

export const mediumDateFormatter = dateString => {
  return moment(dateString).format("Do MMMM YYYY");
};
